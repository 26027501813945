// eslint-disable-next-line import/no-cycle
import { service } from '@/utils/request/service';
// eslint-disable-next-line import/no-cycle
import { tokenService } from '@/utils/request/service-with-token';

// 获取客户数据
export function getClientData() {
  return tokenService({
    url: '/ClientsData/GetClientsDataJson',
    method: 'GET',
  });
}
// 获取机构列表
export function getOrganizationList(params = {}) {
  return service({
    url: '/SystemManage/Organize/GetTreeGridJson',
    method: 'GET',
    params,
  });
}
export function addOrganization(formData, params = {}) {
  return service({
    url: '/SystemManage/Organize/SubmitForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
export function delOrganization(formData, params = {}) {
  return service({
    url: '/SystemManage/Organize/DeleteForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 获取角色列表
export function getRoleList(params = {}) {
  return service({
    url: '/SystemManage/Role/GetGridJson',
    method: 'GET',
    params,
  });
}
export function addRole(formData, params = {}) {
  return service({
    url: '/SystemManage/Role/SubmitForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
export function delRole(formData, params = {}) {
  return service({
    url: '/SystemManage/Role/DeleteForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 获取岗位列表
export function getPostList(params = {}) {
  return service({
    url: '/SystemManage/Duty/GetGridJson',
    method: 'GET',
    params,
  });
}
export function addPost(formData, params = {}) {
  return service({
    url: '/SystemManage/Duty/SubmitForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
export function delPost(formData, params = {}) {
  return service({
    url: '/SystemManage/Duty/DeleteForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 获取用户列表管理
export function getUserList(params = {}) {
  return service({
    url: '/SystemManage/User/GetGridJson',
    method: 'GET',
    params,
  });
}
export function addUser(formData, params = {}) {
  return service({
    url: '/SystemManage/User/SubmitForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 删除User
export function delUser(formData, params = {}) {
  return service({
    url: '/SystemManage/User/DeleteForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}

// 获取区域列表
export function getAreaList(params = {}) {
  return service({
    url: '/SystemManage/Area/GetAllArea',
    method: 'GET',
    params,
  });
}
export function addArea(formData, params = {}) {
  return service({
    url: '/SystemManage/Area/SubmitForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 删除区域
export function delArea(formData, params = {}) {
  return service({
    url: '/SystemManage/Area/DeleteForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 获取数据字典tree
export function getDigitalManagementTree(params = {}) {
  return service({
    url: '/SystemManage/ItemsType/GetTreeJson',
    method: 'GET',
    params,
  });
}
// 获取数据字典
export function getDigitalManagement(params = {}) {
  return service({
    url: '/SystemManage/ItemsData/GetGridJson',
    method: 'GET',
    params,
  });
}
export function addDigitalManagement(formData, params = {}) {
  return service({
    url: '/SystemManage/ItemsData/SubmitForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
export function delDigitalManagement(formData, params = {}) {
  return service({
    url: '/SystemManage/ItemsData/DeleteForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 获取系统菜单
export function getSystemMenus(params = {}) {
  return service({
    url: '/SystemManage/Module/GetTreeGridJson',
    method: 'GET',
    params,
  });
}
// 添加系统菜单
export function addSystemMenu(formData, params = {}) {
  return service({
    url: '/SystemManage/Module/SubmitForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 删除系统菜单
export function delSystemMenu(formData, params = {}) {
  return service({
    url: '/SystemManage/Module/DeleteForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 获取系统参数列表
export function getSystemParameters(params = {}) {
  return service({
    url: '/SystemManage/Param/GetGridJson',
    method: 'GET',
    params,
  });
}
// 添加系统参数
export function addSystemParam(formData, params = {}) {
  return service({
    url: '/SystemManage/Param/SubmitForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 删除系统参数
export function delSystemParam(formData, params = {}) {
  return service({
    url: '/SystemManage/Param/DeleteForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 获取用户区域
export function getUserAreas(params = {}) {
  return service({
    url: '/SystemManage/User/GetUserGridJson',
    method: 'GET',
    params,
  });
}
export function addUserArea(formData, params = {}) {
  return service({
    url: '/SystemManage/UserRegion/SubmitForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
export function delUserArea(formData, params = {}) {
  return service({
    url: '/SystemManage/UserRegion/DeleteForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 获取区域菜单
export function getAreaMenus(params = {}) {
  return service({
    url: '/SystemManage/RegionModule/GetTreeGridJson',
    method: 'GET',
    params,
  });
}
export function addAreaMenu(formData, params = {}) {
  return service({
    url: '/SystemManage/RegionModule/SubmitForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
export function delAreaMenu(formData, params = {}) {
  return service({
    url: '/SystemManage/RegionModule/DeleteForm',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params,
  });
}
// 获取Customer的树型结构
export function getCustomerTree(params = {}) {
  return service({
    url: 'SystemManage/UserRegion/GetPermissionTree',
    method: 'GET',
    params,
  });
}

export function getPageList(params = {}) {
  return service({
    url: '/SystemManage/RoleAuthorize/GetPermissionTreeNoButton',
    method: 'GET',
    params,
  });
}
// 获取单位列表
export function getSystemKeyValueList(codeType) {
  return service({
    url: '/SystemManage/ItemsData/GetSelectJson',
    method: 'GET',
    params: {
      enCode: codeType,
    },
  });
}
// 获取公司列表
export function getCompanyList() {
  return service({
    url: '/SystemManage/Organize/GetGridJson',
    method: 'GET',
  });
}
// 获取部门列表
export function getDepartListByCompany(params = {}) {
  return service({
    url: '/SystemManage/Organize/GetDepartListByOrganizeID',
    method: 'GET',
    params,
  });
}
