const getters = {
  skinType: (state) => state.app.skinType,
  token: (state) => state.user.token,
  accountId: (state) => state.user.accountId,
  mark: (state) => state.user.mark,
  projectList: (state) => state.projects.projectList,
  roleMapInfo: (state) => state.systemManage.role,
  organizeMapInfo: (state) => state.systemManage.organize,
  dutyMapInfo: (state) => state.systemManage.duty,
  authorizeMenuList: (state) => state.systemManage.authorizeMenu,
  roleTypeMapInfo: (state) => state.systemManage.roleTypeMapInfo,
  certificateMapInfo: (state) => state.systemManage.certificateMapInfo,
  unitInfo: (state) => state.systemManage.unitInfo,
  selectProject: (state) => state.projects.selectProject,
};
export default getters;
