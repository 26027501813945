import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import settings from './modules/settings';
// eslint-disable-next-line import/no-cycle
import user from './modules/user';
// eslint-disable-next-line import/no-cycle
import projects from './modules/projects';
// eslint-disable-next-line import/no-cycle
import systemManage from './modules/systemManage';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    settings,
    user,
    projects,
    systemManage,
  },
  getters,
});

export default store;
