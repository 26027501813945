import Axios from 'axios';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import {
  MessageBox,
  Message,
} from 'element-ui';

const BASE_URL = 'http://192.168.1.130:8080/';
const service = Axios.create({
  baseURL: BASE_URL,
  timeout: 5 * 60 * 1000,
});
service.interceptors.request.use(
  // eslint-disable-next-line arrow-body-style
  (config) => {
    // do something before request is sent
    // if (store.getters.token) {
    //   // eslint-disable-next-line no-param-reassign
    //   config.headers.token = getToken();
    // }
    // if (store.getters.mark) {
    //   // eslint-disable-next-line no-param-reassign
    //   config.headers.mark = getMark();
    // }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */
  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    console.log('response', response);
    if (response.status !== 200) {
      if (response.status === 403) {
        // to re-login
        MessageBox.confirm(
          '登录超时, 您可以停留在此页面，或重新登录',
          '确认登出', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning',
          },
        )
          .then(() => {
            store.dispatch('user/resetToken').then(() => {
              window.location.reload();
            });
          });
      }
      Message({
        message: response.statusText,
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(response.statusText);
    }
    const res = response.data;
    if (res.state && res.state !== 'success') {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(new Error(res.message));
    }
    return Promise.resolve(res);
  },
  (error) => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error.message);
  },
);

export {
  BASE_URL,
  service,
};
