<template>
  <div class="guid-page full-bg flex-grow-1" v-on:click="routeToHome"></div>
</template>
<script>
export default {
  name: 'guid-page',
  methods: {
    routeToHome() {
      this.$router.push({
        name: 'project-home',
      });
    },
  },
};
</script>
<style scoped>
.guid-page{
  background-image: url(../assets/images/project/index.jpg);
}
</style>
