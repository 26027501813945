// eslint-disable-next-line import/no-cycle
import { getClientData } from '@/api/system-manage/index';

const state = {
  organize: null,
  role: null,
  duty: null,
  authorizeMenu: null,
  roleTypeMapInfo: null,
  certificate: null,
  unitInfo: null,
};

const mutations = {
  // eslint-disable-next-line no-shadow
  AUTHOR_MENUS: (state, authorizeMenu) => {
    state.authorizeMenu = authorizeMenu;
  },
  // eslint-disable-next-line no-shadow
  ORGANIZE_MAP_INFO: (state, organize) => {
    state.organize = organize;
  },
  // eslint-disable-next-line no-shadow
  ROLE_MAP_INFO: (state, role) => {
    state.role = role;
  },
  // eslint-disable-next-line no-shadow
  DUTY_MAP_INFO: (state, duty) => {
    state.duty = duty;
  },
  // eslint-disable-next-line no-shadow
  ROLE_TYPE_MAP_INFO: (state, role) => {
    state.roleTypeMapInfo = role;
  },
  // certificate
  // eslint-disable-next-line no-shadow
  CERTIFICATE_MAP_INFO: (state, certificate) => {
    state.certificate = certificate;
  },
  // eslint-disable-next-line no-shadow
  UNIT_INFO: (state, unitInfo) => {
    state.unitInfo = unitInfo;
  },
};
const actions = {
  setSystemManageAction({ commit }) {
    getClientData().then((res) => {
      console.log('res', res);
      const {
        organize, role, duty, authorizeMenu,
        dataItems: { RoleType, Certificate, UnitSpan },
      } = res;
      if (!res.authorizeMenu) {
        return;
      }
      commit('AUTHOR_MENUS', JSON.parse(authorizeMenu));
      commit('ORGANIZE_MAP_INFO', organize);
      commit('ROLE_MAP_INFO', role);
      commit('DUTY_MAP_INFO', duty);
      commit('ROLE_TYPE_MAP_INFO', RoleType);
      commit('CERTIFICATE_MAP_INFO', Certificate);
      commit('UNIT_INFO', UnitSpan);
    });
  },
  setAuthMenusAction({ commit }, authorizeMenu) {
    commit('AUTHOR_MENUS', authorizeMenu);
  },
  setOrganizeAction({ commit }, organize) {
    commit('ORGANIZE_MAP_INFO', organize);
  },
  setRoleMapInfoAction({ commit }, role) {
    commit('ROLE_MAP_INFO', role);
  },
  setDutyMapInfoAction({ commit }, duty) {
    commit('DUTY_MAP_INFO', duty);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
