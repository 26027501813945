// eslint-disable-next-line import/no-cycle
import { request } from '@/utils/request/request';
// eslint-disable-next-line import/no-cycle
import { tokenService } from '@/utils/request/service-with-token';

// 获取设备树
export function getEquipTree(data = {}) {
  return request({
    url: 'api/equipment/v1/GetProjectTree',
    method: 'GET',
    params: data,
  });
}
// 获取设备属性
export function getEquipProps(data) {
  return request({
    url: 'api/equipment/v1/GetPropertysByEquipmentID',
    method: 'GET',
    params: data,
  });
}
// 设备详情
export function querydevice(query) {
  return request({
    url: 'api/equipment/v1/EquipmentMainInfoDetails',
    method: 'GET',
    params: query,
  });
}
// 获取设备属性历史值
export function getPropsData(data) {
  return request({
    url: 'api/equipment/v1/GetPropertyData',
    method: 'GET',
    params: data,
  });
}
// 获取通道类型
export function getChannelTypes(data) {
  return request({
    url: 'api/equipment/v1/GetModeList',
    method: 'GET',
    params: data,
  });
}
// 获取设备工况
export function getEquipsCondition(data) {
  return request({
    url: 'api/equipment/v1/GetSheBeiGongKuangList',
    method: 'GET',
    params: data,
  });
}
// 获取方案报告
export function getProjectReports(data) {
  return request({
    url: 'api/equipment/v1/ReportInfos',
    method: 'GET',
    params: data,
  });
}
// 获取辅助监控数据
export function getAuxiliaryMonitor(data) {
  return request({
    url: 'api/equipment/v1/GetFuZhuJianKong',
    method: 'GET',
    params: data,
  });
}
// 添加方案报告
export function addProjectReport(formData) {
  return request({
    url: 'api/equipment/v1/FangAnBaoGaoAdd',
    method: 'POST',
    data: formData,
  });
}
// 删除方案报告
export function delProjectReport(data) {
  return request({
    url: 'api/equipment/v1/FangAnBaoGaoDelete',
    method: 'GET',
    params: data,
  });
}
// 修改方案报告
export function modifyProjectReport(formData) {
  return request({
    url: 'api/equipment/v1/FangAnBaoGaoEdit',
    method: 'POST',
    data: formData,
  });
}
// 获取运维档案列表
export function getOpsFiles(data) {
  return request({
    url: 'api/equipment/v1/YunWeiDangAn',
    method: 'GET',
    params: data,
  });
}
// 添加运维档案
export function addOpsFile(formData) {
  return request({
    url: 'api/equipment/v1/YunWeiDangAnAdd',
    method: 'POST',
    data: formData,
  });
}
// 删除运维档案
export function delOpsFile(data) {
  return request({
    url: 'api/equipment/v1/YunWeiDangAnDelete',
    method: 'GET',
    params: data,
  });
}
// 修改运维档案
export function modifyOpsFile(formData) {
  return request({
    url: 'api/equipment/v1/YunWeiDangAnEdit',
    method: 'POST',
    data: formData,
  });
}
export function getStationBaseReport(params) {
  return tokenService({
    url: 'api/equipment/v1/FangAnBaoGao_ToExcel',
    method: 'GET',
    responseType: 'blob', // important
    params,
  });
}
export function getEquipOperationList(params = {}) {
  return tokenService({
    url: 'api/WF/v1/GetEquipmentWBRecords',
    method: 'GET',
    params,
  });
}
