// eslint-disable-next-line import/no-cycle
import { getEquipTree } from '@/api/equipments/equipments';

const state = {
  projectList: null,
  selectProject: null,
};

const mutations = {
  // eslint-disable-next-line no-shadow
  PROJECT_LIST: (state, projectList) => {
    state.projectList = projectList;
  },
  // eslint-disable-next-line no-shadow
  SELECT_PROJECT: (state, project) => {
    state.selectProject = project;
  },
};

const actions = {
  projectList({ commit }) {
    return getEquipTree({
      // 乐清
      ProjectIdStr: 'NkJDRkMyNDk0NUI2NjAzMA',
      // 西城软件园
      // ProjectIdStr: 'NTExRERCNEJDNDA4QjI0MA',
    }).then((res) => {
      commit('PROJECT_LIST', res);
    });
  },
  selectProject({ commit }, args) {
    return getEquipTree(args).then((res) => {
      commit('SELECT_PROJECT', res);
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
