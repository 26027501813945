// eslint-disable-next-line import/no-unresolved
import Cookies from 'js-cookie';

const TokenKey = 'token';
const MarkKey = 'mark';
const ASP_SESSION = 'ASP.NET_SessionId';
// 获取cookie
export function getASPSessionId() {
  return Cookies.get(ASP_SESSION);
}
// 获取Token
export function getToken() {
  return Cookies.get(TokenKey);
}
// 设置Token
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
// 删除Token
export function removeToken() {
  return Cookies.remove(TokenKey);
}
// 获取Mark
export function getMark() {
  return Cookies.get(MarkKey);
}
// 设置Mark
export function setMark(mark) {
  return Cookies.set(MarkKey, mark);
}
// 删除Mark
export function removeMark() {
  return Cookies.remove(MarkKey);
}
