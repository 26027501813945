// eslint-disable-next-line import/no-cycle
import { getLogin } from '@/api/login/index';
import {
  getToken,
  setToken,
  removeToken,
  getMark,
  setMark,
  removeMark,
} from '@/utils/auth';
import { resetRouter } from '@/router';

const getDefaultState = () => ({
  token: getToken(),
  mark: getMark(),
  name: '',
  avatar: '',
  accountId: 'N0JGMTM5QThGNTExRDI2M0NCQTQwMDAwQTlBODU4RTlEODMwRjA1MUQ4NkFDNzREOEU5OUQzRTEwN0I5NzE3RkIyRDZEQTMzQzJBRTY2OEU',
});

const state = getDefaultState();

const mutations = {
  // eslint-disable-next-line no-shadow
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  // eslint-disable-next-line no-shadow
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  // eslint-disable-next-line no-shadow
  SET_NAME: (state, name) => {
    state.name = name;
  },
  // eslint-disable-next-line no-shadow
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  // 设置账号
  // eslint-disable-next-line no-shadow
  SET_ACCOUNTID: (state, accountId) => {
    state.accountId = accountId;
  },
  // eslint-disable-next-line no-shadow
  SET_MARK: (state, mark) => {
    state.mark = mark;
  },
};

const actions = {
  // user login
  login({ commit }, loginForm) {
    return getLogin(loginForm).then((response) => {
      const data = response;
      commit('SET_TOKEN', data.LoginToken);
      commit('SET_MARK', data.loginMark);
      setToken(data.LoginToken);
      setMark(data.loginMark);
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      removeMark();
      resetRouter();
      commit('RESET_STATE');
      resolve();
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken();
      removeMark();
      commit('RESET_STATE');
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
