// eslint-disable-next-line import/no-cycle
import { request, BASE_URL } from '@/utils/request/request';
// 登录验证码
export const loginVerifyCode = `${BASE_URL}api/APP/v1/GetAuthCode?sid=`;
// 登录
export function getLogin(formData) {
  return request({
    url: 'api/APP/v1/login',
    method: 'POST',
    data: formData,
  });
}
// 登出
export function logout() {
  return request({

  });
}
