import Vue from 'vue';
import Router from 'vue-router';
import GuidPage from '@/views/guid-page.vue';
import projectRoute from '@/router/project';

Vue.use(Router);
// Home route content
export const homeRoute = {
  path: '/home',
  name: 'Home',
  component: () => import('@/views/Home.vue'),
  children: [
    ...projectRoute,
    {
      path: '404',
      name: '404',
      meta: {
        title: '404',
        icon: 'el-icon-s-help',
      },
      component: () => import('@/components/err-pages/404.vue'),
      activeIndex: 'light-map-index',
      isShow: false,
    }],
};
export const routes = [{
  path: '/',
  name: 'guid-page',
  component: GuidPage,
},
homeRoute,
];
const createRouter = () => new Router({
  routes,
});
const router = createRouter();

export function resetRouter() {
  console.log('resetRouter');
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
