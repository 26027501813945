const state = {
  skinType: 1,
};

const mutations = {
  // eslint-disable-next-line no-shadow
  SKIN_TYPE: (state, skinType) => {
    state.skinType = skinType;
  },
};

const actions = {
  toggleDevice({ commit }, skinType) {
    commit('TOGGLE_DEVICE', skinType);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
