import Axios from 'axios';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import {
  MessageBox,
  Message,
} from 'element-ui';
import {
  getToken,
  getMark,
} from '@/utils/auth';

// const BASE_URL = 'http://192.168.1.130:8080/';
// const BASE_URL = 'https://yqcgq.dianliangliang.com:60000/';
const BASE_URL = 'http://47.104.247.47:50000/';

const request = Axios.create({
  baseURL: BASE_URL,
  timeout: 5 * 60 * 1000,
});
request.interceptors.request.use(
  // eslint-disable-next-line arrow-body-style
  (config) => {
    // do something before request is sent
    if (store.getters.token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.token = getToken();
    }
    if (store.getters.mark) {
      // eslint-disable-next-line no-param-reassign
      config.headers.mark = getMark();
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
request.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */
  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    if (response.status === 403) {
      // to re-login
      MessageBox.confirm(
        '登录超时, 您可以停留在此页面，或重新登录',
        '确认登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning',
        },
      )
        .then(() => {
          store.dispatch('user/resetToken').then(() => {
            window.location.reload();
          });
        });
    }
    // if the custom code is not 200, it is judged as an error.
    if (res.ResultCode !== 200) {
      // Message({
      //   message: res.ResultDesc || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000,
      // });

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

      return Promise.reject(new Error(res.ResultDesc || 'Error'));
    }
    if (res.RecordCount) {
      return {
        total: res.RecordCount,
        list: res.Data_Obj,
      };
    }
    return res.Data_Obj;
  },
  (error) => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  },
);

export {
  BASE_URL,
  request,
};
