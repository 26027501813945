import nhtjPng from '@/assets/images/project/navs/nhtj.png';
import tjfxPng from '@/assets/images/project/navs/tjfx.png';
import xffxPng from '@/assets/images/project/navs/xffx.png';
import ycjcPng from '@/assets/images/project/navs/ycjc.png';
import zdyjPng from '@/assets/images/project/navs/zdyj.png';
import ztpjPng from '@/assets/images/project/navs/ztpj.png';

export const projectNavs = [
  {
    path: 'project-operation-monitor',
    name: 'project-operation-monitor',
    meta: {
      title: '运行监控',
      icon: ycjcPng,
    },
    component: () => import('@/components/project-operation-monitor/index.vue'),
  }, {
    path: 'project-statistic-analysis',
    name: 'project-statistic-analysis',
    meta: {
      title: '统计分析',
      icon: tjfxPng,
    },
    component: () => import('@/components/project-statistic-analysis/index.vue'),
  }, {
    path: 'project-condition-evaluation',
    name: 'project-condition-evaluation',
    meta: {
      title: '状态评价',
      icon: ztpjPng,
    },
    component: () => import('@/components/project-condition-evaluation/index.vue'),
  }, {
    path: 'project-energy-consumption',
    name: 'project-energy-consumption',
    meta: {
      title: '能耗统计',
      icon: nhtjPng,
    },
    component: () => import('@/components/project-energy-consumption/index.vue'),
  }, {
    path: 'project-line-loss',
    name: 'project-line-loss',
    meta: {
      title: '线损分析',
      icon: xffxPng,
    },
    component: () => import('@/components/project-line-loss/index.vue'),
  }, {
    path: 'project-initiative-repair',
    name: 'project-initiative-repair',
    meta: {
      title: '主动抢修',
      icon: zdyjPng,
    },
    component: () => import('@/components/project-initiative-repair/index.vue'),

  },
];
export default [{
  path: 'project-home',
  name: 'project-home',
  meta: {
    title: '项目首页',
    icon: 'el-icon-s-opportunity',
  },
  component: () => import('@/components/project-home/index.vue'),
}, {
  path: 'project-equipments-confition',
  name: 'project-equipments-confition',
  meta: {
    title: '设备工况',
    icon: null,
  },
  component: () => import('@/components/project-equipments-condition/index.vue'),
}, {
  path: 'project-order-detail/:eId/:oId?',
  name: 'project-order-detail',
  meta: {
    title: '工单详情',
    icon: null,
  },
  component: () => import('@/components/project-initiative-repair/order-detail.vue'),
}, ...projectNavs];
