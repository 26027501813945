<template>
  <el-container id="app" class="container" :class="{'text-white':skinType}">
    <router-view/>
  </el-container>
</template>
<script>
import {
  getToken,
  getMark,
} from '@/utils/auth';

export default {
  computed: {
    skinType() {
      return this.$store.getters.skinType;
    },
    token() {
      return this.$store.getters.token;
    },
  },
  created() {
    this.changeSkinTypeByLocal();
    this.setBodySkinType();
    this.addLocalStorageChangeListener();
  },
  watch: {
    skinType() {
      this.setBodySkinType();
    },
    token(newVal, oldVal) {
      console.log('--------', newVal, 'getToken()',
        getToken(), ',oldVal:', oldVal);
      console.log('--------', getMark());
      if (newVal) {
        this.$store.dispatch('systemManage/setSystemManageAction');
      }
    },
  },
  methods: {
    setBodySkinType() {
      document.querySelector('body').className = 'dark';
    },
    changeSkinTypeByLocal() {
      const localSkinType = this.getLocalSkinType();
      if (localSkinType === null) {
        return;
      }
      this.$store.commit('app/SKIN_TYPE', localSkinType);
    },
    addLocalStorageChangeListener() {
      window.addEventListener('storage', this.changeSkinTypeByLocal);
    },
    getLocalSkinType() {
      const localSkinType = window.localStorage.getItem('skinType');
      if (localSkinType === null) {
        return null;
      }
      return parseInt(localSkinType, 10);
    },
  },
};
</script>
<style>
body{
  margin:0;
}
html,body,.container{
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
